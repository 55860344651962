import pciDSSCertified from '../../../assets/files/ru/Certificate_PCI_DSS_LLC_2024.pdf';

export default {
    seo: {
      title: 'Сертификат PCI DSS | Банк 131',
      description: 'Сертификат PCI DSS ООО «Банк 131»'
    },
    title: 'Сертификат PCI DSS',
    content:[
        {
            label: 'Дата размещения 15.08.2024',
            title: 'Сертификат PCI DSS.PDF',
            link: pciDSSCertified
        }
    ]
}